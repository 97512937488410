@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";

html {
  scroll-behavior: smooth;
}
a{
  text-decoration: inherit;
  color: inherit;
}
a.link{
  text-decoration: underline;
  color: darkblue;
}
body{
  background-color: wheat;
}
h1{
  font-size: 25pt;
  line-height: 40pt;
}
h5{
  font-size: 18pt;
}
.wheat{
  background-color: #88f2ff;
}
.gray{
  background-color: #ebebeb;
}
.yellow{
  background-color: #ffe404;
}
.dark{
  background-color: #3c3e3f
}
.highlight{
  color: #bf3b4a;
}
.container{
  width: 100%;
    align-items: stretch;
  max-width: 480px;
  margin: auto;
  min-height: 100px;
  background-color: white;
  display: flex;
  padding: 40px 0px 0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  min-height: 100vh;
}
.containered{
  padding: 30px 40px;
  position: relative;
  display: block;
}
.containered>*{
  width: 100%;
}
.mylist li{
  font-size: 14pt;
  line-height: 26pt;
  margin-left: -2pt;
}
ul.mylist li::marker{
  content: '✔ ';
  color: green;
}
.norm_img{
  box-shadow: 1px 1px 3px 1px #bababa;
}
.green_btn{
  background: #41bfdc;
  color: white;
  font-weight: bold;
  border: none;
}
.limit_img{
  
}
.p_lg{
  font-size: 15pt;
}
h6{
  line-height: 23pt;
}
